import type {SelectHTMLAttributes, ReactNode} from 'react';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';

interface SelectOptions {
  label: string;
  value: string;
}

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  name: string;
  label: string;
  options: SelectOptions[];
  error?: any;
  helpText?: string | ReactNode;
  hint?: string | ReactNode;
  labelClassName?: string;
  wrapperClassName?: string;
  errorClassName?: string;
  inputOnErrorClassName?: string;
  defaultValue?: string;
  divider?: boolean;
}

const SelectArrow = () => (
  <svg
    className="w-4 h-auto absolute top-1/2 right-[0.9em] transform -translate-y-1/2"
    fill="none"
    viewBox="0 0 13 6"
  >
    <path
      fill="#919EAB"
      fillRule="evenodd"
      d="m7.207 6-4.89-4.7L3.635 0l3.553 3.415L10.634.009l1.336 1.284L7.207 6Z"
      clipRule="evenodd"
    />
  </svg>
);

const Divider = () => (
  <svg
    className="absolute top-1/2 right-[2.4em] transform -translate-y-1/2"
    width="2"
    height="20"
    viewBox="0 0 2 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.23312"
      y="0.256557"
      width="0.513115"
      height="19.4869"
      fill="white"
      stroke="#C4CDD5"
      strokeWidth="0.513115"
    />
  </svg>
);

export default function Select({
  id,
  name,
  label,
  options,
  error,
  helpText,
  hint,
  wrapperClassName = '',
  labelClassName = '',
  errorClassName = '',
  inputOnErrorClassName = '',
  defaultValue,
  divider = false,
  ...selectProps
}: SelectProps) {
  const errorId = `${id}-error`;
  const hintId = `${id}-hint`;
  const helpTextId = `${id}-help-text`;
  const describedBy = cn(selectProps['aria-describedby'], {
    [errorId]: error,
    [hintId]: hint,
    [helpTextId]: helpText,
  });

  let errorText;
  const errorType = typeof error;
  if (errorType === 'string') {
    errorText = error;
  } else if (errorType === 'object' && error?.errorMessage) {
    errorText = error.errorMessage;
  }

  const errorProps = errorText
    ? {
        dangerouslySetInnerHTML: {__html: errorText},
      }
    : {children: error};

  const selectOptions = options.map(({label: text, ...optionProps}, index) => (
    <option
      className="w-full"
      {...optionProps}
      key={`option-${index}`}
      data-label={text}
    >
      {text}
    </option>
  ));

  return (
    <fieldset className={twMerge('mb-4 flex-1 text-left', wrapperClassName)}>
      <div
        className={cn(
          'relative w-full text-black bg-white px-[0.9em] pt-4 pb-2 rounded inline-block border border-transparent shadow-[0_0_0_1px] shadow-legacy-gray-70 ease-[ease] duration-300 transition-all focus:shadow-[0_0_0_2px] focus:shadow-legacy-green-70',
          {
            [inputOnErrorClassName]: error,
            'shadow-[0_0_0_2px] shadow-legacy-pink-70 focus:shadow-[0_0_0_2px] focus:shadow-legacy-pink-70':
              error,
            'shadow-[0_0_0_2px] !shadow-legacy-cyan-20 focus:shadow-[0_0_0_2px] focus:shadow-legacy-cyan-20':
              hint,
          },
        )}
      >
        <label
          className={twMerge('absolute top-1.5 left-4 text-xs', labelClassName)}
          htmlFor={id}
        >
          {label}
        </label>
        <select
          {...selectProps}
          name={name}
          id={id}
          className="
          block
          w-full
          text-lg
          appearance-none
          bg-transparent
          "
          aria-describedby={describedBy}
          {...(defaultValue && {defaultValue})}
        >
          {selectOptions}
        </select>
        {divider && <Divider />}
        <SelectArrow />
      </div>
      {error ? (
        <span
          className={twMerge(
            'shadow-[0_0_0_2px] shadow-legacy-pink-70 text-white bg-legacy-pink-70 block py-[0.4em] px-[0.9em] text-xs font-medium rounded-[0_0_4px_4px] leading-[1.6em]',
            errorClassName,
          )}
          id={errorId}
          {...errorProps}
        />
      ) : (
        hint && (
          <span
            className="shadow-[0_0_0_2px] shadow-legacy-cyan-20 text-black bg-legacy-cyan-20 block py-[0.4em] px-[0.9em] text-xs font-medium rounded-[0_0_4px_4px] leading-[1.6em]"
            id={hintId}
          >
            {hint}
          </span>
        )
      )}
      {helpText && (
        <span
          className="py-0 px-[0.9em] my-4 mx-0 block leading-[1.6em]"
          id={helpTextId}
        >
          {helpText}
        </span>
      )}
    </fieldset>
  );
}
